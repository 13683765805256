import Vue from 'vue';
import VueRouter from 'vue-router';
import Meta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(Meta)

const router = new VueRouter({
    mode: 'history',
    base: "/",
    routes: [
        {
            path: '/',
            name: 'HomePage',
            component: () => import("@/components/pagina/homepage"),
            meta: {
                title: 'Home - Sodoma',
                favicon: 'https://www.sodoma.pe/favicon.ico'
            }
        },

        {
            path: '/perfil',
            name: 'PerfilUsuario',
            component: () => import("@/components/clientes/perfil.vue"),
            
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/puerta/login',
            name: 'LogPuerta',
            component: () => import("@/components/puerta/logpuerta"),

        },
        {
            path: '/puerta/ver',
            name: 'VerPuerta',
            component: () => import("@/components/puerta/verpuerta"),
        },
        {
            path: '/puerta/cliente',
            name: 'ClientePuerta',
            component: () => import("@/components/puerta/clientepuerta"),

        },
        {
            path: '/password',
            name: 'PasswordPerfil',
            component: () => import("@/components/clientes/password.vue"),
            
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/fotoperfil',
            name: 'FotoPerfil',
            component: () => import("@/components/clientes/fotoperfil.vue"),
            
            meta: {
                layout: 'AdminClubLayout'
            }
        },

        {
            path: '/direccion',
            name: 'DireccionPerfil',
            component: () => import("@/components/clientes/direccion"),
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/tickets',
            name: 'ListaTickets',
            component: () => import("@/components/clientes/tickets"),
            meta: {
                layout: 'AdminClubLayout'
            }

        },

        {
            path: '/entrada',
            name: 'entrada',
            component: () => import("@/components/clientes/entrada")
        },



        {
            path: '/tkt/:persona/:codigo',
            name: 'tkt',
            component: () => import("@/components/clientes/tkt")
        },


        {
            path: '/cliente/crear',
            name: 'CreaCliente',
            component: () => import("@/components/clientes/creacliente")
        },

        {
            path: '/politicas',
            name: 'politicas',
            component: () => import("@/components/pagina/politicas"),

        },
        {
            path: '/terminos',
            name: 'terminos',
            component: () => import("@/components/pagina/terminos"),

        },

        {
            path: '/login',
            name: 'LoginPagina',
            component: () => import("@/components/pagina/login")

        },


        {
            path: '/inicio',
            name: 'InicioPagina',
            component: () => import("@/components/conectado/Inicio"),
            meta: {
                layout: 'AdminClubLayout'
            }
        },


        {
            path: '/qrevento',
            name: 'QRevento',
            component: () => import("@/components/eventos/LeeQREv"),
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/entrada/email',
            name: 'EmailEntradas',
            component: () => import("@/components/eventos/EmailEntradas"),
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/acto/evento',
            name: 'ActoEvento',
            component: () => import("@/components/eventos/actoEv"),
            meta: {
                layout: 'AdminClubLayout'
            }
        },

        {
            path: '/acto',
            name: 'acto',
            component: () => import("@/components/pagina/acto")
        },
        {
            path: '/conectarse',
            name: 'conectarse',
            component: () => import("@/components/pagina/conectarse"),
            meta: {
                layout: 'WithoutLayout'
            }
        },
        {
            path: '/canjea/:fecha/:codigo',
            name: 'CanjeaCompra',
            component: () => import("@/components/pagina/canjea"),
            meta: {
                layout: 'WithoutLayout'
            }
        },
        {
            path: '/cortesias/:hash',
            name: 'CortesiasQr',
            component: () => import("@/components/promotores/cortesias"),
            meta: {
                layout: 'WithoutLayout'
            }
        },
        {
            path: '/compra',
            name: 'compra',
            component: () => import("@/components/pagina/compra"),
            meta: {
                layout: 'AdminClubLayout'
            }
        },

        {
            path: '/carro',
            name: 'CarroCompra',
            component: () => import("@/components/pagina/carro"),
            meta: {
                layout: 'AdminClubLayout'
            }
        },

        {
            path: '/eventos/ver',
            name: 'AdministrarEvento',
            component: () => import("@/components/eventos/Evento.vue"),
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/eventos/menu',
            name: 'MenuEventos',
            component: () => import("@/components/eventos/Eventos.vue"),
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/eventos/lista',
            name: 'ListaEventos',
            component: () => import("@/components/eventos/MisEventos.vue"),
            meta: {
                layout: 'AdminClubLayout'
            }
        },

        {
            path: '/eventos/reporte/puerta',
            name: 'ReportePuerta',
            component: () => import("@/components/eventos/reportes/Puerta.vue"),
            meta: {
                layout: 'AdminClubLayout'
            }
        },

        {
            path: '/eventos/reporte/contactos',
            name: 'ReporteContactos',
            component: () => import("@/components/eventos/ReporteContactos"),
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/eventos/inscritos',
            name: 'InscritosEventos',
            component: () => import("@/components/eventos/Inscritos"),
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/eventos/dashboard',
            name: 'DashboardEventos',
            component: () => import("@/components/eventos/DashboardEv"),
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/miscompras',
            name: 'MisCompras',
            component: () => import("@/components/clientes/miscompras"),
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/eventos/compras',
            name: 'MisCompra',
            component: () => import("@/components/eventos/Compras"),
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/crea/evento',
            name: 'CreaPaso1',
            component: () => import("@/components/eventos/CreaPaso1"),
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/crea/evento/segundo',
            name: 'CreaPaso2',
            component: () => import("@/components/eventos/CreaPaso2"),
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '/crea/evento/tercero',
            name: 'CreaPaso3',
            component: () => import("@/components/eventos/CreaPaso3"),
            meta: {
                layout: 'AdminClubLayout'
            }
        },

        {
            path: '/eventos/promotores',
            name: 'AdministrarContactos',
            component: () => import("@/components/eventos/Contactos"),
            meta: {
                layout: 'AdminClubLayout'
            }
        },
        {
            path: '*',
            name: 'error',
            component: () => import("@/error/notfound")
        }
    ]
});
router.beforeEach((to, from, next) => {
    // Cambiar el título dinámicamente
    if (to.meta.title) {
        document.title = to.meta.title;
    } else {
        document.title = 'Sodoma'; // Título por defecto
    }

    // Cambiar el favicon dinámicamente
    if (to.meta.favicon) {
        const link = document.querySelector("link[rel~='icon']");
        if (link) {
            link.href = to.meta.favicon;
        } else {
            const newLink = document.createElement('link');
            newLink.rel = 'icon';
            newLink.href = to.meta.favicon;
            document.head.appendChild(newLink);
        }
    }

    next();
});
export default router
